import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { useForm } from "react-hook-form"

const ContactForm = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm()

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const requiredMessage = "This field is required"
  const formRows = [
    {
      fields: [
        {
          name: "firstName",
          title: "First Name",
          validation: {
            required: { value: true, message: requiredMessage },
            maxLength: 255,
          },
        },
        {
          name: "lastName",
          title: "Last Name",
          validation: {
            required: { value: true, message: requiredMessage },
            maxLength: 255,
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "email",
          title: "Email",
          type: "email",
          validation: {
            required: { value: true, message: requiredMessage },
            pattern: {
              value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              message: "Email address is invalid",
            },
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "phone",
          title: "Phone",
          type: "phone",
          validation: {
            pattern: {
              value: /^[+]?[()\-0-9]+$/i,
              message: "Phone number is invalid",
            },
          },
        },
      ],
    },
    {
      fields: [
        {
          name: "company",
          title: "Company",
          validation: { maxLength: 255 },
        },
      ],
    },
    {
      fields: [
        {
          name: "message",
          title: "Your message...",
          type: "textarea",
          validation: {
            required: { value: true, message: requiredMessage },
          },
        },
      ],
    },
  ]

  const onSubmit = (data) => {
    setIsSubmitting(true)
    const functionUrl =
      "https://dotaction-function.azurewebsites.net/api/ContactFormProcess?code=43wNLRQZArJiAufvpe5SabzjeYBH4THJ3wLevSHngAE67BfTaJUTKQ=="
    fetch(functionUrl, {
      method: "POST",
      body: JSON.stringify(data),
    }).finally(() => {
      setIsSubmitting(false)
      navigate("/contact-thanks")
    })
    /*.then(() => {
        alert("Success")
      })
      .catch(() => {
        alert("Error")
      })*/
    //console.log(data)
    //https://dotaction-function.azurewebsites.net/api/ContactFormProcess?code=43wNLRQZArJiAufvpe5SabzjeYBH4THJ3wLevSHngAE67BfTaJUTKQ==
  }

  return (
    <FormElement
      onSubmit={handleSubmit(onSubmit)}
      noValidate="true"
      autoComplete="off"
    >
      {props.title && <FormTitle>{props.title}</FormTitle>}{" "}
      <input
        type="hidden"
        {...register("subject")}
        value={props.subject ? props.subject : "General"}
      />
      {formRows.map((row) => (
        <FormRow>
          {row.fields.map((field) => {
            const watchValue = watch(field.name)
            const error =
              errors && errors[field.name] ? errors[field.name].message : ""
            const className = `${watchValue && "active"} ${error && "error"}`
            const requiredField =
              field.validation &&
              field.validation.required &&
              field.validation.required.value

            return (
              <FormColumn>
                <InputContainer htmlFor={field.name} className={className}>
                  {(() => {
                    switch (field.type) {
                      case "textarea":
                        return (
                          <textarea
                            id={field.name}
                            name={field.name}
                            required={requiredField}
                            {...register(field.name, field.validation)}
                          ></textarea>
                        )
                      default:
                        return (
                          <input
                            id={field.name}
                            name={field.name}
                            type={field.type}
                            required={requiredField}
                            {...register(field.name, field.validation)}
                          />
                        )
                    }
                  })()}
                  <InputBorder className={className}></InputBorder>
                  <InputLabel className={className}>
                    {field.title} {requiredField && <>*</>}
                  </InputLabel>
                </InputContainer>
                {error && <InputError>{error}</InputError>}
              </FormColumn>
            )
          })}
        </FormRow>
      ))}
      <BottomRow>
        <SubmitButton disabled={isSubmitting}>Submit</SubmitButton>
        <PrivacyAgreement>
          By clicking the button you agree with our{" "}
          <Link to="/privacy-policy" title="privacy policy">
            privacy policy
          </Link>
          .
        </PrivacyAgreement>
      </BottomRow>
    </FormElement>
  )
}

export default ContactForm

const FormElement = styled.form`
  display: block;
  position: relative;

  a {
    color: ${(props) => props.theme.color.accent};
    text-decoration: none;
    ${(props) => props.theme.animation.regular}
    :hover {
      color: ${(props) => props.theme.color.primary};
    }
  }
`

const FormTitle = styled.h3`
  text-align: center;
`

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -17px;
`

const FormColumn = styled.div`
  padding: 0 17px;
  margin-bottom: 15px;
  flex-grow: 1;
  min-width: 350px;
`

const BottomRow = styled.div`
  margin-bottom: 15px;
  text-align: center;
`

const PrivacyAgreement = styled.p`
  font-size: 0.875rem;
  line-height: 1.2rem;
`

const SubmitButton = styled.button`
  background-color: ${(props) => props.theme.color.accent};
  color: ${(props) => props.theme.color.white.regular};

  &:disabled {
    background-color: ${(props) => props.theme.color.white.dark};
    color: ${(props) => props.theme.color.primary};
  }
  font-size: 1.125rem;
  line-height: 1.125rem;
  padding: 1.125rem 1.625rem 0.875rem;
  font-weight: bold;
  min-width: 150px;
`

const InputContainer = styled.label`
  background-color: transparent;
  position: relative;
  width: 100%;
  display: block;
  cursor: text;

  input,
  textarea {
    display: block;
    width: 100%;

    -webkit-appearance: none;
    border-radius: 0;
    border: none;
    outline: none;
    box-shadow: none;

    font-size: 1.125rem;
    line-height: 2rem;
    padding: 1.125rem 1rem 0.375rem;

    background: transparent;
    background-color: transparent;
    z-index: 1;

    position: relative;
    ${(props) => props.theme.animation.regular}
  }
`
const InputBorder = styled.div`
  border: 1px solid ${(props) => props.theme.color.black.lightest};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  &.active {
    border-color: ${(props) => props.theme.color.black.light};
  }

  &.error {
    border-color: ${(props) => props.theme.color.error};
  }
`
const InputLabel = styled.div`
  font-size: 1.125rem;
  padding: 1.125rem 1.625rem 0.875rem;

  position: absolute;
  /*font-size: .875rem;
  font-weight: 300;*/
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.theme.animation.regular}

  &.active {
    transform: translate3d(-10px, -13px, 0);
    font-size: 0.6875rem;
  }

  &.error {
    color: ${(props) => props.theme.color.error};
  }
`
const InputError = styled.div`
  color: ${(props) => props.theme.color.error};
  line-height: 2rem;
  font-size: 0.8rem;
`
